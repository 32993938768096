import { QuestionCircleOutlined } from "@ant-design/icons"
import { Button, Popconfirm } from "antd";
import { useDelete, useNotification, useOne, useTranslate, useUpdate } from "@refinedev/core";
import { IAttachedFile, IQuote } from "interfaces"
import { MdOutlineDeleteForever } from "react-icons/md"

type DeleteFileButtonProps = {
    fileID: number,
    quote: IQuote
}

export const DeleteFileButton = ({fileID, quote}: DeleteFileButtonProps) => {

    const deleteFromDB = useDelete().mutate
    const { open } = useNotification();  
    const translate = useTranslate();

    const fileData = useOne<IAttachedFile>({
        resource: 'attached-files',
        id: fileID,
        meta: {
            populate: ['file']
        }
    })
    const updateQuote = useUpdate<IQuote>().mutate

    const updatedFileRelation = quote.attachedFiles.filter((f) => f.id !== fileID)

    const deleteAttachment = () => {
        const fileToDelete = fileData!.data!.data.file.id
        
        deleteFromDB({
            resource: 'upload/files', 
            id: fileToDelete, 
            successNotification: false
        })
        deleteFromDB({
            resource: 'attached-files', 
            id: fileID, 
            successNotification: false
        })
        updateQuote({
            resource: 'quotes',
            id: quote.id,
            values: {attachedFiles: updatedFileRelation},
            successNotification: false
        })
        
        open!({
            message: translate("quotes.notifications.deleteAttachmentSuccess"),
            type: "success"
        })
    }

    return (
        <>
        <Popconfirm
            title="Voulez-vous supprimer ce fichier ?"
            onConfirm={() => deleteAttachment()}
            okText="Supprimer"
            cancelText="Annuler"
            placement="left"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            okButtonProps={{danger: true}}
        >
            <Button type="link" style={{fontSize: "20px"}} danger><MdOutlineDeleteForever /></Button>
        </Popconfirm>
        </>
    )

}