import { Space } from "antd";
import { CSSProperties } from "react"

type Props = {
    title: string,
    children?: React.ReactNode,
    style?: CSSProperties,
}

export const Description = ({title, children, style}: Props) => {

    return (
        <Space direction="vertical" style={style}>
        <span style={{
            color: "darkgrey",
            paddingLeft: 10,
        }}>
            {title}
        </span>
        <span>{children}</span>
        </Space>
    )
}