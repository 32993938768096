import { Show } from "@refinedev/antd";
import { Col, Divider, Row, Space, Tag, Typography } from "antd";
import { useShow, useTranslate } from "@refinedev/core";
import { IInvoice, IInvoiceItemArray } from "interfaces";
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { CSSProperties, Fragment, Suspense } from "react";
import { priceDisplay } from "helpers/invoices";
import { Description } from "components/quotes/attachments/description";
// import PDFDownloadButton from "components/invoices/pdfDownloadButton";
import { lazy } from "react";

const { Title } = Typography;

export const InvoiceShow = () => {

    const t = useTranslate();

    const { queryResult } = useShow<IInvoice>({
        meta:{
            populate: ['customer', 'address']
        }
    });
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const setTitle = () => {

        const containerStyle: CSSProperties = {
            display: "flex",
            alignItems: "center",
            gap: 10
        }

        return (<div style={containerStyle}>
                    {t('invoices.' + record?.type)} 
                    {(record?.publishedAt === null) && <Tag color="red">{t('invoices.draft')}</Tag>}
                </div>);
    }

    const PDFDownloadButton = lazy(() => import("components/invoices/pdfDownloadButton"));

    return (
        <>
        {/* <PDFViewer width="100%" height="700px">
                <InvoicePDF data={record} />
        </PDFViewer> */}
        <Show isLoading={isLoading}
              title={setTitle()}
              canDelete={record?.publishedAt === null}
              canEdit={record?.publishedAt === null}
              headerButtons={({defaultButtons}) => (
                <>
                <Suspense>
                    <PDFDownloadButton record={record} />
                </Suspense>
                {defaultButtons}
                </>
              )}
        >

            {/* Header */}
            <Row gutter={[16,16]}>
                <Col span={12}>
                    <Space size={50}>
                    <Description title={t("invoices.show.submissionDate")} >
                        <b>{dayjs(record?.submissionDate).format('DD MMMM YYYY')}</b>
                    </Description>
                    <Description title={t("invoices.show.validityDate")}>
                        <b>{dayjs(record?.validityDate).format('DD MMMM YYYY')}</b>
                    </Description>
                    </Space>
                </Col>
                
                <Col span={12} style={{display: "flex", justifyContent: "right"}}>
                    <Description title={t("invoices.create.invoiceID", {type: t('invoices.' + record?.type).toLowerCase()})}
                                 style={{textAlign: "right"}}
                    >
                        <b>{record?.invoiceID}</b>
                    </Description>
                </Col>
            </Row>

            <Divider  />

            <Row gutter={[16, 16]}>
                <Col span={14}>
                    {/* Title */}
                    <Space direction="vertical" size="large">
                            <Description title={t('invoices.create.titlePlaceholder')}><h3>{record?.title}</h3></Description>
                            {/* <Typography.Title level={4}>{record?.title}</Typography.Title> */}
                            <Description title={t('invoices.create.descriptionPlaceholder')}>
                                <span style={{whiteSpace: "pre-line"}}>{record?.description}</span>
                            </Description>
                    </Space>

                </Col>

                <Col span={2}></Col>

                {/* Customer info */}
                <Col span={8} style={{textAlign: "right"}}>
                    <Description title="Infomation client">
                        {record?.customer &&
                            <Title level={4}>{record?.customer.surname} {record?.customer.name}</Title>
                        }
                        {record?.address && 
                            record?.address.fullAddress.split(',').map((v, i) => (<span key={i}>{v}<br/></span>))
                        }
                    </Description>
                </Col>

                {/* Address info */}
                {/* <Col span={11}  style={{textAlign: "right"}}>
                    <Space direction="vertical" size={2}>
                        <b>Amperius</b>
                        <>Rue du Louvroy 18</>
                        <>6120 Nalinnes</>
                        <br/>
                        <>info@amperius.be</>
                        <>0499/61.39.49</>
                    </Space>
                </Col> */}
            </Row>

            

            {/* Items */}
            <Row style={{marginTop: "30px", marginBottom: "30px"}}>
                <Col span={24}>
                    <InvoiceItems items={record?.items} />
                </Col>
            </Row>
            
            {/* Summary */}

            <InvoiceSummary record={record} />
        </Show>
        </>

    )
}

const InvoiceItems = ({items}: {items: IInvoiceItemArray | undefined}) => {

    const t = useTranslate();
    
    const spanOthers = 3;
    const spanDescription = 24-5*spanOthers;

    const headerStyle: CSSProperties = {
        textAlign: "center",
        fontWeight: "600",
    }

    const headerStyleRight: CSSProperties = {
        textAlign: "right",
        fontWeight: "600",
    }

    return (
        <>
        <Row gutter={[16, 16]}>
            <Col span={spanDescription} style={{...headerStyle, textAlign: "left"}}>{t('invoices.descriptionItem')}</Col>
            <Col span={spanOthers} style={headerStyle}>{t('invoices.create.unitCostPrice')}</Col>
            <Col span={spanOthers} style={headerStyle}>{t('invoices.create.unitPrice')}</Col>
            <Col span={spanOthers} style={headerStyle}>{t('invoices.quantity')}</Col>
            <Col span={spanOthers} style={headerStyle}>{t('invoices.vat')}</Col>
            <Col span={spanOthers} style={headerStyleRight}>{t('invoices.list.totalItemExclVAT')}</Col>
        </Row>
        <Divider style={{marginTop: "10px", marginBottom: "10px"}} />
        
        
        <Row gutter={[16, 16]}>
        {items?.map((value, index) => (
            <Fragment key={index}>
                <Col span={spanDescription}>
                    {value.optional && <><b>Option</b> <br/></>}
                    <span style={{whiteSpace: "pre-line"}}>{value.description}</span>
                </Col>

                <Col span={spanOthers} style={{textAlign: "right", paddingRight: "55px"}} >
                    {priceDisplay(value.unitCostPrice)}
                </Col>
                
                <Col span={spanOthers} style={{textAlign: "right", paddingRight: "55px"}} >
                    {priceDisplay(value.unitPrice)}
                </Col>
                
                <Col span={spanOthers} style={{textAlign: "center"}} >
                    {value.quantity}
                </Col>
                
                <Col span={spanOthers} style={{textAlign: "center"}} >
                    {value.vat*100}%
                </Col>

                <Col span={spanOthers} style={{textAlign: "right"}} >
                    {!value.optional && priceDisplay(value.unitPrice * value.quantity)}
                </Col>
            </Fragment>
            
        ))}
        </Row>
        
        </>
    )
}

const InvoiceSummary = ({record}: {record: IInvoice | undefined}) => {
    
    const t = useTranslate();

    return (
        <Row justify="end">
            <Col span={8}>
                <Row>

                    <Col span={12}>
                        <b>{t('invoices.subtotal')}</b>
                    </Col>
                    <Col span={12} style={{display: "flex", justifyContent:"right"}}>
                        {priceDisplay(record?.subtotal)}
                    </Col>

                    {/* <Col span={12}>
                        <b>Remise</b>
                    </Col>
                    <Col span={12} style={{display: "flex", justifyContent:"right"}}>
                        1.00€
                    </Col> */}

                    <Col span={12}>
                        <b>{t('invoices.taxes')}</b>
                    </Col>
                    <Col span={12} style={{display: "flex", justifyContent:"right"}}>
                        {priceDisplay(record?.taxes)}
                    </Col>
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}} />

                    <Col span={12}>
                        <b>{t('invoices.total')}</b>
                    </Col>
                    <Col span={12} style={{display: "flex", justifyContent:"right"}}>
                        <b>{priceDisplay(record?.total)}</b>
                    </Col>

                </Row>
            </Col>
        </Row>
    )

}