import { Button, Col, Form, Input, Row } from "antd";
import { useApiUrl } from "@refinedev/core";
import { axiosInstance } from "authProvider";
import { IAttachedFile } from "interfaces";
import { Dispatch, SetStateAction } from "react";

type UpdateFileFormProps = {
    item: IAttachedFile,
    setEditID: Dispatch<SetStateAction<number>>,
    refetch: any
}

export const UpdateFileForm = ({item, setEditID, refetch}: UpdateFileFormProps) => {

    const { TextArea } = Input;

    const apiUrl = useApiUrl();

    const updateFileName = (value: any) => {
        const data = {
            data: {
                name: value.name,
                description: value.description
            }
        }
        const res = axiosInstance.put(`${apiUrl}/attached-files/${value.id}`, data)
        res.then((e) => {
            setEditID(0)
            refetch()
        }) 
    }

    return (
        <Form 
            autoComplete="off"
            onFinish={updateFileName}
            style={{width: "100%"}}
            initialValues={{
                'id': item.id,
                'name': item.name,
                'description': item.description
            }}
        >
            <Form.Item name="id" hidden rules={[{'required': true, 'type': 'number', 'enum': [item.id]}]} ><Input hidden disabled/></Form.Item>
            <Row wrap={false} gutter={16}>
                <Col flex="auto">
                    <Form.Item name="name" rules={[{'required': true, 'type': 'string', 'max': 255}]}><Input /></Form.Item>
                </Col>
                <Col flex="none">
                    <Form.Item><Button onClick={() => setEditID(0)}>Annuler</Button></Form.Item>
                </Col>
                <Col flex="none">
                    <Form.Item><Button htmlType="submit" type="primary">Enregistrer</Button></Form.Item>
                </Col>
            </Row>
            <Row>
                <Col flex="auto">
                <Form.Item name="description">
                    <TextArea autoSize={{minRows: 3, maxRows: 10}}></TextArea>
                </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}