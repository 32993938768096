import { Tag } from "antd";
import { IInvoiceItemArray } from "interfaces";

export const priceDisplay = (value?: number) => {
    return value !== undefined ? value.toLocaleString('fr-be', {style: "currency", currency: "EUR"}) : "-.--€";
}

const calculatePrice = (items: IInvoiceItemArray | undefined , type: "subtotal" | "taxes" | "total") => {

    // Verify that item exists and is defined
    if (typeof items !== "undefined" && items !== null) {
        if (typeof items[0] !== "undefined"){
            
            // Calculate the sum
            const result = items.reduce(
                (acc, curr) => {
                    //Ignore if element of the items array is undefined
                    if (typeof curr !== "undefined") {
                        // Check that all the properties are present and not null
                        if ("quantity" in curr && "unitPrice" in curr && "vat" in curr && "optional" in curr) {
                            if (curr.quantity !== null && curr.vat !== null && curr.unitPrice !== null) {
                                //Ignore if optional item
                                if (!curr.optional) {
                                    let taxeFactor;
                                    switch (type) {
                                        case "subtotal":
                                            taxeFactor = 1;
                                            break;
                                        case "taxes":
                                            taxeFactor = curr.vat;
                                            break;
                                        case "total":
                                            taxeFactor = curr.vat + 1;
                                    }
                                    return acc + (curr.quantity * curr.unitPrice) * taxeFactor;
                                }
                            }
                            
                        }
                    }
                    return acc;
                }, 0
            )
            
            // Format the number to currency. Rounding by toLocaleString
            return result
        }
    } 
    return 0 
} 

export const calculateSubtotal = (items: IInvoiceItemArray | undefined) => (calculatePrice(items, "subtotal"));
export const calculateTaxes = (items: IInvoiceItemArray | undefined) => (calculatePrice(items, "taxes"));
export const calculateTotal = (items: IInvoiceItemArray | undefined) => (calculatePrice(items, "total"));

export const displaySubtotal = (items: IInvoiceItemArray | undefined) => (priceDisplay(calculatePrice(items, "subtotal")));
export const displayTaxes = (items: IInvoiceItemArray | undefined) => (priceDisplay(calculatePrice(items, "taxes")));
export const displayTotal = (items: IInvoiceItemArray | undefined) => (priceDisplay(calculatePrice(items, "total")));

export const formatType = (type: string, tagLabel: string) => {
        
    const color = (type === "quotation") ? "geekblue" : "green"

    return <><Tag color={color}>{tagLabel}</Tag></>;
}