import {
    CreateButton,
    DeleteButton,
    EmailField,
    RefreshButton,
    Show,
    useModal,
    useTable,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
    Button,
    Card,
    Col,
    Descriptions,
    Grid,
    Image,
    Modal,
    ModalProps,
    Popover,
    Progress,
    Row,
    Space,
    Table,
    Tag,
    Typography,
} from "antd";

import {
    BaseKey,
    useCreate,
    useList,
    useNavigation,
    useShow,
    useTranslate,
    useUpdate,
} from "@refinedev/core";

import { AttachedFiles } from "components/quotes/attachments/attachedFiles";
import { IQuote, ICarArray, ICar, IInvoice, ICustomer, IAddress } from "interfaces";
import { descriptionContentStyle, descriptionlabelStyle, roundBorderImageStyle } from "theme";
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { Key, useEffect } from "react";
import { priceDisplay, formatType } from "helpers/invoices";
import { StatusTag } from "components/quotes/StatusTag";

const { Title, Text, Paragraph } = Typography;

export const QuotePopulate = ['cars', 'cars.car_brand', 'meter', 'breaker', 'panel', 'attachedFiles', 'attachedFiles.file', 'invoices']

export const QuoteShow = () => {

    const t = useTranslate();
    const {list, edit} = useNavigation();

    // Get data
    const { queryResult, showId } = useShow<IQuote>({
        meta: {
            populate: QuotePopulate
        }
    });
    
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const { mutate: mutateQuote } = useUpdate<IQuote>();
    const changeStatus = (status: IQuote['status']) => {
        if(record) {
            mutateQuote({
                resource: 'quotes',
                id: record.id.toString(),
                values: {status: status}
            })
        }
    }

    // // Color the status tag
    // const statusTagColor = (status: IQuote['status']) => {
    //     switch (status) {
    //         case 'Nouveau':
    //             return 'green'
    //         case 'Envoyé':
    //             return 'orange'
    //         case 'À exécuter':
    //             return 'magenta'
    //         default:
    //             return 'default'
    //     }
    // }

    const {mutate: mutateInvoice} = useCreate<IInvoice>();
    const {mutate: mutateCustomer} = useCreate<ICustomer>();
    const {mutate: mutateAddress} = useCreate<IAddress>();

    const addressesQueryResults = useList<IAddress>({
        resource: "addresses",

        // queryOptions: { enabled: false },
        meta: {
            populate: 'customer'
        },

        filters: [{
            field: "fullAddress",
            operator: "eq",
            value: record?.fullAddress
        }],

        pagination: {
            pageSize: 1000
        }
    })

    
    
    const {tableProps: customerTableProps, setFilters} = useTable<ICustomer>({
        resource: "customers",
        queryOptions: {enabled: record !== undefined},

        pagination: {
            pageSize: 10
        },

        filters: {
            permanent: [{
                operator: "or",
                value: [
                    {
                        field: "name",
                        operator: "contains",
                        value: record?.name
                    },
                    {
                        field: "surname",
                        operator: "contains",
                        value: record?.surname
                    },
                    {
                        field: "email",
                        operator: "contains",
                        value: record?.email
                    },
                    {
                        field: "phone",
                        operator: "contains",
                        value: record?.phone
                    }
                ]
            }]
        }
    })

    useEffect(() => {
        if (record !== undefined) {
            if (record.company !== null) {
                setFilters([
                    {
                        field: "company",
                        operator: "contains",
                        value: record?.company
                    }
                ])
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record])
    
    const { show: customerModalShow, close: customerModalClose,  modalProps: customerModalProps } = useModal();
    const CustomerListModal = ({modalProps}: {modalProps: ModalProps}) => {

        let customerID: Key = 0;
        return (
        <Modal {...modalProps} 
            title={t('quotes.show.createInvoiceModalTitle')}            
            closable={false}
            footer={<Space size="small">
                <Button onClick={customerModalClose}>Annuler</Button>,
                <CreateButton type="primary" onClick={() => {customerModalClose(); createInvoice(customerID)}}>Créer</CreateButton>
                </Space>
            }>
            <>{t('quotes.show.createInvoiceModalDescription')}</>
            <br/><br/>
            <>{t('quotes.show.createInvoiceModalInstructions')}</>
            
            <Table {...customerTableProps} rowKey="id" 
                size="small"
                rowSelection={{type: "radio", onChange: (record)=>(customerID = record[0])}}
                style={{marginTop: 20}}
            >
                <Table.Column<ICustomer> dataIndex="name" 
                    render={(value, record) => (<>{record.surname} {record.name}</>)}
                    title={<>{t('customers.info.surname')}, {t('customers.info.name')}</>}
                />
                <Table.Column dataIndex="email" title={t('customers.info.email')} />
                <Table.Column dataIndex="phone" title={t('customers.info.phone')} />
            </Table>
            
        
        </Modal>
        )
    }

    // Decide if the customer modal list must be shown
    const showCustomerModal = () => {
        //Show a modal to list customers in the database that have similar names/email/phone number
        //Ask to select a user or to create a new one.
        if (customerTableProps.dataSource?.length) {
            customerModalShow();
        }
        else createInvoice(0);
    }

    const createInvoice = (customerID?: Key) => {
        


        // If a customer is selected
        if(customerID !== 0) {
            const index = addressesQueryResults.data!.data.findIndex(e => e.customer.id === customerID);
            const addressID = addressesQueryResults.data?.data[index].id;
            let tempInvoiceID: number;
            mutateInvoice({
                resource: 'invoices',
                values: {
                    type: 'quotation',
                    customer: customerID,
                    address: addressID,
                    submissionDate: dayjs().toISOString(),
                    validityDate: dayjs().add(1, 'month').toISOString(),
                    publishedAt: null,
                    quote: record?.id
                }
            },
            {
                onSuccess: (data) => {
                    tempInvoiceID = data.data.data.id
                    mutateQuote({
                        resource: 'quotes',
                        id: record!.id,
                        values: {customer: customerID, address: addressID}
                    },
                    {
                        onSuccess: (data) => {
                            edit('invoices', tempInvoiceID)
                        }
                    })
                    
                }
            })
        }
        else {
            //Create the customer

            let tempCustomerID: number;
            let tempAddressID: number;

            mutateCustomer({
                resource: 'customers',
                values: {
                    name: record?.name,
                    surname: record?.surname,
                    email: record?.email,
                    phone: '+' + record?.phone,
                    company: record?.company,
                    tva: record?.tva,
                    fullName: record?.surname + ' ' + record?.name,
                    quotes: record?.id
                }
            },
            {
                //Once the customer is created
                onSuccess: (data) => {
                    tempCustomerID = data.data.data.id;
                    //Create address
                    mutateAddress({
                        resource: 'addresses',
                        values: {
                            fullAddress: record?.fullAddress,
                            customer: tempCustomerID,
                            quotes: record?.id
                        }
                    },
                    {
                        //Once the address is created
                        onSuccess: (data) => {
                            tempAddressID = data.data.data.id;
                            mutateQuote({
                                resource: 'quotes',
                                id: record!.id,
                                values: {customer: tempCustomerID, address: tempAddressID}
                            })
                            mutateInvoice({
                                resource: 'invoices',
                                values: {
                                    type: 'quotation',
                                    customer: tempCustomerID,
                                    address: tempAddressID,
                                    submissionDate: dayjs().toISOString(),
                                    validityDate: dayjs().add(1, 'month').toISOString(),
                                    publishedAt: null,
                                    quote: record?.id
                                }
                            },
                            {   
                                onSuccess: (data) => edit('invoices', data.data.data.id)                                    
                            })
                        }
                    })
                }
            })
        }

    }

    return (
        <Show isLoading={isLoading} 
            breadcrumb=""
            title={<>
                <span>{t("quotes.show.title", {surname:record?.surname, name:record?.name })}</span>
                <StatusTag status={record?.status!} style={{
                    marginLeft: "10px",
                    marginTop:  "5px",
                    verticalAlign: "top"
                }} />
            </>}
            headerProps={{
                style: {
                    backgroundColor: "inherit",
                },
            }}
            contentProps={{
                style: {
                    backgroundColor: "inherit",
                },
            }}
            canDelete={true}
            headerButtons={({defaultButtons}) => (
                <>
                    <CreateButton onClick={() => showCustomerModal()}>{t('quotes.show.createInvoice')}</CreateButton>
                    <Popover
                        content={<Space direction="vertical">
                            <Button disabled={record?.status === 'new'} onClick={() => changeStatus('new')}>{t("quotes.show.status.new")}</Button>
                            <Button disabled={record?.status === 'quoteSent'} onClick={() => changeStatus('quoteSent')}>{t("quotes.show.status.quoteSent")}</Button>
                            <Button disabled={record?.status === 'lost'} onClick={() => changeStatus('lost')}>{t("quotes.show.status.lost")}</Button>
                            <Button disabled={record?.status === 'ordered'} onClick={() => changeStatus('ordered')}>{t("quotes.show.status.ordered")}</Button>
                            <Button disabled={record?.status === 'placed'} onClick={() => changeStatus('placed')}>{t("quotes.show.status.placed")}</Button>
                            <Button disabled={record?.status === 'invoiceSent'} onClick={() => changeStatus('invoiceSent')}>{t("quotes.show.status.invoiceSent")}</Button>
                            <Button disabled={record?.status === 'done'} onClick={() => changeStatus('done')}>{t("quotes.show.status.done")}</Button>
                        </Space>}
                        >
                            <Button>{t("quotes.show.statusChange")}</Button>
                    </Popover>
                    
                    <RefreshButton meta={{
                            populate: QuotePopulate
                        }} 
                    />
                    
                    <DeleteButton onSuccess={() => list("quotes")}
                        confirmTitle={t("quotes.show.confirmDelete")} 
                        confirmOkText={t("quotes.show.yes")} 
                        confirmCancelText={t("quotes.show.no")} />
                </>
            )}
         >
            <CustomerListModal modalProps={customerModalProps} />
            
            <Row gutter={[16, 16]}>
                
                {/* Contact details */}
                <Col xl={7} lg={24} xs={24}>
                    <ContactDetails record={record} />
                </Col>

                <Col xl={17} lg={24} xs={24}>
                    <Row gutter={[16, 16]}>
                        {/* Setup details */}
                        <Col span={24}>
                            <SetupDetails record={record} />
                        </Col>

                        {/* Car info */}
                        <Col span={24}>
                            <CarsInfo record={record} />
                        </Col>

                        <Col span={24}>
                            <InvoicesList showId={showId} />
                        </Col>

                        {/* Attachements */}
                        <Col span={24}>
                            <Card title={t("quotes.show.attachments")}>
                                {record?.id && <AttachedFiles quoteId={record?.id!} quote={record!} />}
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Show>
    );

}

const ContactDetails = ({record}: {record: IQuote | undefined} ) => {
    
    const t = useTranslate();
    const { xl } = Grid.useBreakpoint();
    // Add a custom badge depending if it is an private or a company
    const customerBageType = (record: IQuote | undefined) => {

        if (!record?.company && !record?.tva) {
            return <Tag color="blue"><b>{t("quotes.show.individual")}</b></Tag>
        } 
        else {
            return <Tag color="volcano"><b>{t("quotes.show.company")}</b></Tag>
        }
    }
    
    return (
        <Card>
            <Space
                direction="vertical"
                style={{
                    width: "100%",
                    textAlign: xl ? "unset" : "center",
                }} >
                <Row justify="space-between">
                    <Col>
                        <Title level={4} >{t("quotes.show.contactDetails")}</Title>
                    </Col>
                    <Col style={{
                        marginTop: "3px"
                    }}>
                        {/* ! at the end because I know that it will exist */}
                        {customerBageType(record)} 
                    </Col>
                </Row>

                <Title level={5} >{record?.surname} {record?.name}</Title>
                
                {record?.company &&
                    <Text><Icons.ShopOutlined /> {record?.company}</Text>
                }
                {record?.company &&
                    <Text><b>{t("quotes.show.tva")}</b> {record?.tva}</Text>
                }

                <Text><Icons.MailOutlined /> <EmailField value={record?.email}/></Text>
                <Text><Icons.PhoneOutlined /> +{record?.phone}</Text>
                <Text><Icons.HomeOutlined /> {record?.fullAddress}</Text>
                <Image 
                    style={{
                        marginTop: "10px",
                        maxWidth: "260px"
                    }}
                src={"https://maps.googleapis.com/maps/api/staticmap?center=" + record?.fullAddress + "&zoom=10&scale=2&size=480x400&maptype=roadmap&key=AIzaSyANg9kqYGyEUdcVruQ-NuEi8MuP3Dr5rI4&format=png&visual_refresh=true&markers=size:small%7Ccolor:0xff0000%7Clabel:%7C" + record?.fullAddress}/>
            </Space>
        </Card>
    )
}

const SetupDetails = ({record}: {record: IQuote | undefined} ) => {

    const t = useTranslate();

    // Translate the location
    const locationName = (location: IQuote['location']) => {
        switch (location) {
            case 'inside':
                return t("quotes.show.inside")
            case 'outside':
                return t("quotes.show.outside")
            case 'unknown':
                return t("quotes.show.unknown")
        }
    }

    // Translate the where info
    const whereName = (where: IQuote['where']) => {
        switch (where) {
            case 'home':
                return t("quotes.show.home")
            case 'appartment':
                return t("quotes.show.appartment")
            case 'company':
                return t("quotes.show.company")
            case 'public':
                return t("quotes.show.publicSpace")
        }
    }

    return (
        <Card title={t("quotes.show.setupDetails")}>
            <Descriptions colon={false}
                            labelStyle={descriptionlabelStyle}
                            contentStyle={descriptionContentStyle}
                            layout="vertical"
                            size="small"
                            >
                <Descriptions.Item label={t("quotes.show.amountOfPlugs")}>{record?.amountOfPlugs}</Descriptions.Item>
                <Descriptions.Item label={t("quotes.show.location")}>{locationName(record?.location!)}</Descriptions.Item>
                <Descriptions.Item label={t("quotes.show.where")}>{whereName(record?.where!)}</Descriptions.Item>
            </Descriptions>

            <Row gutter={16} style={{marginTop: "20px"}}>
            <Image.PreviewGroup>
                {record?.meter &&
                <Col span={8}>
                    <Paragraph style={descriptionlabelStyle}>{t("quotes.show.meter")}</Paragraph>
                    <Image src={record?.meter.url} style={roundBorderImageStyle} />
                </Col>
                }
                {record?.breaker &&
                <Col span={8}>
                    <Paragraph style={descriptionlabelStyle}>{t("quotes.show.breaker")}</Paragraph>
                    <Image src={record?.breaker.url} style={roundBorderImageStyle} />
                </Col>
                }
                {record?.panel &&
                <Col span={8}>
                    <Paragraph style={descriptionlabelStyle}>{t("quotes.show.panel")}</Paragraph>
                    <Image src={record?.panel.url} style={roundBorderImageStyle} />
                </Col>
                }
            </Image.PreviewGroup>
            </Row>
        </Card>
    )
}

const CarsInfo = ({record}: {record: IQuote | undefined} ) => {

    const t = useTranslate();

    // Translate the car type
    const carTypeName = (carType: IQuote['carType']) => {
        switch (carType) {
            case 'ev':
                return t("quotes.show.ev")
            case 'hybrid':
                return t("quotes.show.hybrid")
            case 'other':
                return t("quotes.show.other")
        }
    }

    const showCarModels = (evcars: ICarArray) => {
        if(evcars instanceof Array) {
            return evcars.map( (e: ICar) => <Tag color="geekblue" key={e.model}>{e.car_brand.name} - {e.model}</Tag> )
        }
    }

    return (
    <Card title={t("quotes.show.carsInfo")}>
        <Descriptions colon={false}
                    labelStyle={descriptionlabelStyle}
                    contentStyle={descriptionContentStyle}
                    layout="vertical"
                    size="small"
                    column={2}
                    >
            <Descriptions.Item label={t("quotes.show.cars")}>{carTypeName(record?.carType!)}</Descriptions.Item>
            <Descriptions.Item label={t("quotes.show.kmPerDay")}>
                <Progress type="line" 
                        percent={(record?.km!)/350*100} 
                        format={() => record?.km + ' km'}
                        style={{width: "200px"}}
                    />
            </Descriptions.Item>
            <Descriptions.Item label={t("quotes.show.carModels")}>{showCarModels(record?.cars!)}</Descriptions.Item>
        </Descriptions>
    </Card>
    )
}

const InvoicesList = ({showId}: {showId: BaseKey | undefined}) => {

    const t = useTranslate();

    const {tableProps} = useTable<IInvoice>({
        resource: "invoices",

        meta: {
            populate: "quote",
            publicationState: 'preview', 
        },

        pagination: {
            pageSize: 10
        },

        filters: {
            permanent: [
                {
                    field: "quote.id",
                    operator: "eq",
                    value: showId
                }
            ]
        }
    })

    return (
        <Card title={t('quotes.show.invoices')}>
            <Table {...tableProps} rowKey="id">
                <Table.Column dataIndex="invoiceID" title={t('quotes.show.invoiceID')}/>
                <Table.Column dataIndex="type" title={t('invoices.list.type')} 
                    render={(value) => (formatType(value, t("invoices." + value)))}
                />
                <Table.Column dataIndex="publishedAt" title={t('invoices.list.status')} 
                    render={(value) => (<>{value === null && <Tag color="red">{t("invoices.draft")}</Tag>}</>)}
                />
                <Table.Column dataIndex="title" title={t('quotes.show.invoiceTitle')} />
                <Table.Column dataIndex="total" title={t('quotes.show.invoiceTotal')} 
                    render={(v) => priceDisplay(v)} />
            </Table>
        </Card>
    )

}