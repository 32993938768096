import { Progress, Row } from "antd";
import { useApiUrl } from "@refinedev/core";
import { axiosInstance } from "authProvider";
import { IMedia } from "interfaces";
import { useEffect, useState } from "react"
import { IUploadingFile } from "./attachedFiles";

export type UploadFileProps = {
    fileInfo: IUploadingFile;
    quoteId: number;
    onUpload: (id: number) => void
}

export function UploadFileWithProgress({fileInfo, quoteId, onUpload}: UploadFileProps) {

    const apiUrl = useApiUrl();

    const [progress, setProgress] = useState(0)

    useEffect( () => {
        async function upload() {
            await uploadFile(fileInfo, quoteId, apiUrl, onUpload, setProgress);
        }
        upload()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <>
        <Row style={{width: "100%"}} >
            <p>{fileInfo.file.name}</p><br/>
            <Progress percent={progress} />
        </Row>
        </>
    )
}

async function uploadFile(fileInfo: IUploadingFile, 
                          quoteId: number, 
                          apiUrl: string,
                          onUpload: (id: number) => void,
                          setProgress: (percentage: number) => void) {

    const config = {
        onUploadProgress: function(progressEvent: ProgressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            setProgress(percentCompleted)
            }
    }

    
    const data = {name: fileInfo.file.name, description: ""}
    const formData = new FormData()
    formData.append('files.file', fileInfo.file, fileInfo.file.name)
    formData.append('data', JSON.stringify(data))

    const res = axiosInstance.post(`${apiUrl}/attached-files`, formData, config)
    
    res.then((r) => {
        if (r.status === 200){
            const fileID = r.data.data.id
            axiosInstance.get(`${apiUrl}/quotes/${quoteId}?populate[0]=attachedFiles&fields[0]=id`).then((r) => {
                if(r.status === 200){
                    const attachedFilesID = r.data.data.attributes.attachedFiles.data.map((i: IMedia) => i.id)
                    const data = {
                        data: {
                            attachedFiles: [...attachedFilesID, fileID]
                        }
                    }
                    axiosInstance.put(`${apiUrl}/quotes/${quoteId}`, data).then((r) => {
                        if (r.status === 200) {
                            onUpload(fileInfo.id)
                        }
                    }).catch((e) => console.log(e))
                }
                
            }).catch((e) => console.log(e))
        }
    }).catch((r) => console.log(r))

    



}