import { DeleteOutlined, ExportOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Edit, useForm, useSelect } from "@refinedev/antd"
import { useList, useTranslate } from "@refinedev/core";
import { IAddress, ICustomer, IInvoice, IInvoiceItemArray } from "interfaces"
import { CSSProperties, useEffect } from "react";
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import locale from "antd/locale/fr_FR";
import { FormListOperation } from 'antd/es/form/FormList';
import { calculateSubtotal, calculateTaxes, calculateTotal, displaySubtotal, displayTaxes, displayTotal } from "helpers/invoices";
import { Button, Col, ConfigProvider, DatePicker, Divider, Form, FormProps, Input, InputNumber, Popconfirm, Radio, Row, Select, Switch } from "antd";


export const InvoiceEdit = () => {

    const t = useTranslate();

    const { formProps, saveButtonProps, id } = useForm<IInvoice>({
        metaData:{
            populate: ['customer', 'address']
        },
        warnWhenUnsavedChanges: true,
    });
    saveButtonProps.onClick = () => {
        // Make sur publsihedAt is null
        formProps.form?.setFieldValue('publishedAt', null)
        formProps.form?.submit()
    }


    if (formProps.initialValues) {
        formProps.initialValues.submissionDate = dayjs(formProps.initialValues?.submissionDate);
        formProps.initialValues.validityDate = dayjs(formProps.initialValues?.validityDate);
        if ('customer' in formProps.initialValues){
            formProps.initialValues.customerID = formProps.initialValues.customer.id
            // delete formProps.initialValues.customer;

        }
        if ('address' in formProps.initialValues){
            formProps.initialValues.addressID = formProps.initialValues.address.id
            // delete formProps.initialValues.address;

        }
    }      

    const PublishButton = () => (
        <Popconfirm title={t('invoices.create.popupPublishTitle', 
                                {type: t('invoices.' + formProps.form?.getFieldValue('type')).toLowerCase()}
                            )}
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    description={t('invoices.create.popupPublishDescription')}
                    okText={t('invoices.create.publish')}
                    cancelText={t('buttons.cancel')}
                    onConfirm={() => {
                                    formProps.form?.setFieldValue('publishedAt', new Date().toISOString())
                                    formProps.form?.submit()
                                }}
        >
            <Button icon={<ExportOutlined/>} 
                    >
                {t('invoices.create.publish')}
            </Button>
        </Popconfirm>
    )

    // const customerID = Form.useWatch('customer', formProps.form);
    const invoiceTypeWatch = Form.useWatch('type', formProps.form);

    const latestInvoice = useList<IInvoice>({
        resource: "invoices",
        metaData:{
            publicationState: 'preview'
        },
        config: {
            sort: [{
                field: "id",
                order: "desc"
            }],
            filters: [{
                field: "type",
                operator: "eq",
                value: "invoice"
            },{
                field: "id",
                operator: "ne",
                value: id
            },
            {
                field: 'invoiceID',
                operator: "nnull",
                value: true
            }],
            pagination: {
                pageSize: 1
            }
        }
    })
    const latestQuotation = useList<IInvoice>({
        resource: "invoices",
        metaData:{
            publicationState: 'preview'
        },
        config: {
            sort: [{
                field: "id",
                order: "desc"
            }],
            filters: [{
                field: "type",
                operator: "eq",
                value: "quotation",
            },{
                field: "id",
                operator: "ne",
                value: id
            },
            {
                field: 'invoiceID',
                operator: "nnull",
                value: true
            }],
            pagination: {
                pageSize: 1
            }
        }

    })

    // Generate InvoiceID
    useEffect(() => {
        const today = new Date();
        const todayMonth = (today.getMonth()+1).toLocaleString('fr-be', {
            minimumIntegerDigits: 2,
            useGrouping: false});
        const todayYear = (today.getFullYear()-2000).toLocaleString('fr-be', {
            minimumIntegerDigits: 2,
            useGrouping: false});

        let r, typeString;
        switch (invoiceTypeWatch) {
            case "quotation":
                r = latestQuotation;
                typeString = 'D';
                break;
            case "invoice": 
                r = latestInvoice;
                typeString = 'I';
                break;
        }

        if(r?.data?.data.length) {
            const id = r.data.data[0].invoiceID;
            const year = id.slice(6,8);
            const idIncr = id.slice(1,4);

            if (todayYear === year) {
                const newID = parseInt(idIncr) + 1;
                const newIDString = newID.toLocaleString('fr-be', {
                    minimumIntegerDigits: 3,
                    useGrouping: false});
                formProps.form?.setFieldValue('invoiceID', typeString + newIDString + todayMonth + todayYear);
                return;
            }                 
        }

        formProps.form?.setFieldValue('invoiceID', typeString + "001" + todayMonth + todayYear);
        return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceTypeWatch, latestInvoice, latestQuotation])


    return (
        <Edit saveButtonProps={saveButtonProps}
                footerButtons={({defaultButtons})=>(
                    <>
                    <PublishButton />
                    {defaultButtons}
                    </>
                )}
                title={t("invoices.create.title")} 
                isLoading={(formProps.initialValues === undefined) ? true : false}
        >
            <Form {...formProps} layout="vertical" >

                <Form.Item name="publishedAt" hidden>
                    <Input />
                </Form.Item>

                {/* Type */}
                <Row>
                    <Col span={24}>
                        <Form.Item name="type">
                            <Radio.Group >
                                <Radio.Button value="quotation">{t("invoices.quotation")}</Radio.Button>
                                <Radio.Button value="invoice">{t("invoices.invoice")}</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                {/* Header */}
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <ConfigProvider locale={locale} >
                            <Form.Item name="submissionDate" 
                                label={t("invoices.submissionDate")}
                            >
                                <DatePicker style={{width: "180px"}} />
                            </Form.Item>
                            {invoiceTypeWatch==='quotation' &&
                            <Form.Item name="validityDate"  
                                label={t("invoices.create.validityDate")}
                            >
                                <DatePicker style={{width: "180px"}}  />
                            </Form.Item>
                            }
                        </ConfigProvider>
                    </Col>
                    
                    <Col span={12} style={{display: "flex", justifyContent: "right"}}>
                        <Form.Item name="invoiceID" 
                            style={{width: "50%"}}
                            label={
                                t("invoices.create.invoiceID", {type: t('invoices.'+invoiceTypeWatch).toLowerCase()})
                            }                            
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider style={{marginTop: "0px", marginBottom: "40px"}} />
                
                {formProps.initialValues !== undefined && 
                <>
                    <CustomerAddressFields formProps={formProps} />

                    {/* Title */}
                    <Row>
                        <Col span={8}>
                            <Form.Item name="title" label={t("invoices.create.titlePlaceholder")}>
                                <Input placeholder={t("invoices.create.titlePlaceholder")} />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    {/* Description */}
                    <Row>
                        <Col span={14}>
                            <Form.Item name="description" label={t("invoices.create.descriptionPlaceholder")}>
                                <Input.TextArea autoSize={{minRows: 4}} placeholder={t("invoices.create.descriptionPlaceholder")}>
                                </Input.TextArea>
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Items */}
                    <Row>
                        <Col span={24}>
                            <ItemsForm items={formProps.initialValues.items} />
                        </Col>
                    </Row>

                    {/* Summary */}
                    <InvoiceSum formProps={formProps} />
                </>
                }

            </Form>
        </Edit>
    )
}

const CustomerAddressFields = ({formProps}: {formProps: FormProps}) => {
    
    if('customerID' in formProps.initialValues! && 'addressID' in formProps.initialValues!){
        return (
            <Row gutter={[16, 16]}>
                {/* Customer info */}
                <Col span={11}>
                        <CustomerFields id={formProps.initialValues.customerID} />
                </Col>
    
                <Col span={2}></Col>
    
                {/* Address info */}
                <Col span={11}>
                        <AddressField id={formProps.initialValues!.addressID} customerID={formProps.initialValues.customerID} /> 
                </Col>
            </Row>
        )
    }
    return <></>
    
}

const CustomerFields = ({id}: {id: number}) => {

    const t = useTranslate();

    const { selectProps } = useSelect<ICustomer>({
        resource: "customers",
        optionLabel: "fullName",
        // optionValue: "id",
        pagination: {pageSize: 5},
        defaultValue: 0,
        filters: [
            {
                field: "id",
                operator: "eq",
                value: id
            }
        ]
    });
    

    let defaultValue = undefined;
    if (selectProps.options?.length) {
        defaultValue = {
            label: selectProps.options[0].label!.toString(),
            value: selectProps.options[0].value!.toString()
        }
    }  

    return (
    <>
        <Form.Item name="customer" label={t('invoices.customer')}>
            <>
                {defaultValue !== undefined && <><Select {...selectProps} 
                    defaultValue={defaultValue}
                    disabled
                />
                {/* Trick to show the default value is to show a hidden p */}
                <p style={{display: "none"}}>{defaultValue?.label}</p>
                </>
                }
            </>
        </Form.Item>
        
    </>
    )
}

const AddressField = ({id, customerID}: {id: number, customerID: number}) => {

    const t = useTranslate();

    const { selectProps } = useSelect<IAddress>({
        resource: "addresses",
        optionLabel: "fullAddress",
        pagination: {pageSize: 5},
        filters: [
            {
                field: "customer.id",
                operator: "eq",
                value: customerID,
            },
        ],
        defaultValue: id
    });
    
    let defaultValue = undefined;
    if (selectProps.options?.length) {
        defaultValue = {
            label: selectProps.options[0].label!.toString(),
            value: selectProps.options[0].value!.toString()
        }
    }  


    return (
        <>
        {defaultValue !== undefined &&
        <Form.Item name="address" label={t('invoices.address')}>
            <><Select {...selectProps} 
                defaultValue={defaultValue}
                disabled
            />
            {/* Trick to show the default value is to show a hidden p */}
            <p style={{display: "none"}}>{defaultValue?.label}</p>
            </>
        </Form.Item>
        }
        </>
    )


}

const ItemsForm = ({items}: {items: IInvoiceItemArray}) => {

    const t = useTranslate();

    // const { selectProps } = useSelect<IVAT>({
    //     resource: "vats",
    //     optionLabel: "name",
    //     optionValue: "percentage",
    //     pagination: {pageSize: 10}
    // });

    const vat = [
        {
            "label": "0%",
            "value": 0
        },
        {
            "label": "6%",
            "value": 0.06
        },
        {
            "label": "12%",
            "value": 0.12
        },
        {
            "label": "21%",
            "value": 0.21
        }
    ]

    const spanPrices = 3;
    const spanActions = 1;
    const spanAmount = 2;
    const spanVAT = 2;
    const spanDescription = 24-2*spanPrices-2*spanActions-spanAmount-spanVAT;

    const defaultVAT = {value: "0.06", label: "6%"}

    const defaultValues = {
        "unitPrice": 0, 
        "quantity": 1, 
        "vat": defaultVAT.value,
        "optional": false
    }

    const headerStyle: CSSProperties = {
        textAlign: "center",
        fontWeight: "600",
    }

    // const defaultItems = (items !== null) ? [] : items;

    return (
        <>
        <Row gutter={[16, 16]}>
            <Col span={spanDescription} style={{...headerStyle, textAlign: "left"}}>{t('invoices.descriptionItem')}</Col>
            <Col span={spanPrices} style={headerStyle}>{t('invoices.create.unitCostPrice')}</Col>
            <Col span={spanPrices} style={headerStyle}>{t('invoices.create.unitPrice')}</Col>
            <Col span={spanAmount} style={headerStyle}>{t('invoices.quantity')}</Col>
            <Col span={spanVAT} style={headerStyle}>{t('invoices.vat')}</Col>
            <Col span={spanActions} style={headerStyle}>{t('invoices.create.optionalItem')}</Col>
            {/* <Col span={spanActions} style={headerStyle}>{t('invoices.create.action')}</Col> */}
            <Col span={spanActions} style={headerStyle}></Col>
        </Row>
        <Divider style={{marginTop: "10px"}} />
        <Form.List name="items" >
            {(fields, {add, remove}: FormListOperation) => (
                <>
                {fields.map((field) => (
                    
                    <Row gutter={[16, 16]} key={field.key} >

                        <Col span={spanDescription}>
                            <Form.Item
                                {...field}
                                name={[field.name, "description"]}
                                rules={[{ required: true}]}
                            >
                                <Input.TextArea rows={3} placeholder={t('invoices.descriptionItem')}/>
                            </Form.Item>
                        </Col>

                        <Col span={spanPrices}>
                            <Form.Item
                                {...field}
                                name={[field.name, "unitCostPrice"]}
                                // rules={[{ required: true}]}
                                initialValue={0.00}
                            >
                                <InputNumber 
                                    decimalSeparator=","
                                    precision={2}
                                    controls={false}
                                    addonAfter="€"
                                />
                            </Form.Item>
                        </Col>
                        
                        <Col span={spanPrices}>
                            <Form.Item
                                {...field}
                                name={[field.name, "unitPrice"]}
                                // rules={[{ required: true}]}
                            >
                                <InputNumber 
                                    decimalSeparator=","
                                    precision={2}
                                    controls={false}
                                    addonAfter="€"
                                    defaultValue={0}
                                />
                            </Form.Item>
                        </Col>
                        
                        <Col span={spanAmount}>
                            <Form.Item
                                {...field}
                                name={[field.name, "quantity"]}
                                rules={[{ required: true}]}
                            >
                                <InputNumber 
                                        min={1}
                                        />
                            </Form.Item>
                        </Col>
                        
                        <Col span={spanVAT}>
                            <Form.Item
                                {...field}
                                name={[field.name, "vat"]}
                                rules={[{ required: true}]}
                                // initialValue={defaultVAT.value}
                                valuePropName="option"
                            >
                                <Select options={vat} 
                                        showSearch
                                        defaultValue={(Array.isArray(items) && field.key < items.length) ? items[field.key].vat : 0.06 } 
                                />
                            </Form.Item>
                        </Col>

                        <Col span={spanActions} style={{textAlign: "center"}}>
                            <Form.Item 
                                {...field}
                                name={[field.name, "optional"]}
                                valuePropName="checked"
                            >
                                <Switch size="small" />
                            </Form.Item>
                        </Col>

                        <Col span={spanActions} style={{textAlign: "center"}}>
                            <Button type="primary" 
                                    shape="circle" 
                                    icon={<DeleteOutlined />} 
                                    danger
                                    onClick={() => remove(field.name)} />
                        </Col>
                        
                    </Row>
                ))}

                <Form.Item>
                    <Button type="primary" 
                            icon={<PlusOutlined />}
                            onClick={ () => add(defaultValues) }>
                        {t('invoices.create.addItem')}
                    </Button>
                </Form.Item>
                </>
            )}
        </Form.List>
        </>
    )
}

const InvoiceSum = ({formProps}: {formProps: FormProps}) => {

    const form = Form.useFormInstance();

    const t = useTranslate();

    const items: IInvoiceItemArray | undefined = Form.useWatch('items', form);
    
    useEffect(() => {
      formProps.form?.setFieldValue('subtotal', calculateSubtotal(items))
      formProps.form?.setFieldValue('taxes', calculateTaxes(items))
      formProps.form?.setFieldValue('total', calculateTotal(items))
    }, [formProps.form, items])
    

    return (
        <Row justify="end">
            <Col span={8}>
                <Row>

                    <Col span={12}>
                        <b>{t('invoices.subtotal')}</b>
                    </Col>
                    <Col span={12} style={{display: "flex", justifyContent:"right"}}>
                        {displaySubtotal(items)}
                        <Form.Item name="subtotal" 
                                    initialValue={formProps.initialValues?.subtotal} 
                                    hidden
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    {/* <Col span={12}>
                        <b>Remise</b>
                    </Col>
                    <Col span={12} style={{display: "flex", justifyContent:"right"}}>
                        1.00€
                    </Col> */}

                    <Col span={12}>
                        <b>{t('invoices.taxes')}</b>
                    </Col>
                    <Col span={12} style={{display: "flex", justifyContent:"right"}}>
                        {displayTaxes(items)}
                        <Form.Item name="taxes" 
                                    initialValue={formProps.initialValues?.subtotal} 
                                    hidden
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Divider style={{marginTop: "10px", marginBottom: "10px"}} />

                    <Col span={12}>
                        <b>{t('invoices.total')}</b>
                    </Col>
                    <Col span={12} style={{display: "flex", justifyContent:"right"}}>
                        <b>{displayTotal(items)}</b>
                        <Form.Item name="total" 
                                    initialValue={formProps.initialValues?.subtotal} 
                                    hidden
                        >
                            <Input/>    
                        </Form.Item>
                    </Col>

                </Row>
            </Col>
        </Row>
    )
}
