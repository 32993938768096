import { List as AntdList, Card } from "antd";
import { useTranslate } from "@refinedev/core";

export const Tools = () => {

    const t = useTranslate();

    const data = [
        {
            title: t('tools.cableCalculator'),
            link: 'https://www.elandcables.com/fr/cable-calculator'
        },
        {
            title: t('tools.rexel'),
            link: 'https://netstore.rexel.be/'
        },
        {
            title: t('tools.image_single'),
            link: window.location.origin + '/images/borne_single.jpg'
        },
        {
            title: t('tools.image_pro'),
            link: window.location.origin + '/images/borne_pro.jpg'
        }
    ]

    return (
        <>
        <Card>
            <AntdList 
            itemLayout="vertical"
            size="large"
            split={true}
            dataSource={data}
            renderItem={(item) => (
                <AntdList.Item>
                    <AntdList.Item.Meta
                    title={item.title}
                    description={
                        <a href={item.link} target="_blank" rel="noreferrer">
                            {item.link}
                        </a>
                    }
                    />
                </AntdList.Item>
            )}
            />
        </Card>
        </>
    )
}