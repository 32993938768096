import { grey } from '@ant-design/colors';


export const descriptionlabelStyle = {
    fontSize: "13px",
    fontWeight: "500",
    // marginBottom: "-5px",
    // lineHeight: "1.5px",
    // color: "hsl(231,36%,63%)"
    color: grey[1]
}

export const descriptionContentStyle = {
    fontWeight: "700",
    fontSize: "17px",
    paddingLeft: "5px",
    marginTop: "-10px"
}

export const roundBorderImageStyle = {
    borderRadius: "10px"
}



