import { DateField, DeleteButton, EditButton, EmailField, List, SaveButton, TextField, useEditableTable, useModalForm, useTable } from "@refinedev/antd"
import {Button, Card, Col, Form, Grid, Input, Modal, Row, Space, Table, Tag, Tooltip, Typography  } from "antd";
import { useNavigation, useOne, useShow, useTranslate } from "@refinedev/core"
import { GOOGLE_PLACE_API } from "../../constants";
import { IAddress, ICustomer, IInvoice, IQuote } from "interfaces";
import { useState } from "react";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import { IoWarningOutline } from "react-icons/io5";
import { formatType } from "helpers/invoices";
import { CalendarOutlined, MailOutlined, PhoneOutlined, ShopOutlined } from "@ant-design/icons";
import { StatusTag } from "components/quotes/StatusTag";

const { useBreakpoint } = Grid;

interface IState {
    label: string | undefined;
    value: IPlaceValue | undefined;
  }
interface IPlaceValue {
    description: string;
    matched_substrings: Array<{length: number, offset: number}>;
    place_id: string;
    reference: string;
    structured_formatting: Array<{offset: number, value: string}>;
    types: Array<string>;
}


export const CustomerShow = () => {

    const { xl } = useBreakpoint();
    const t = useTranslate();
    const { show } = useNavigation();

    
    const { queryResult, showId } = useShow<ICustomer>();
    const { data } = queryResult;
    const customer = data?.data;

    const { refetch } = useOne<ICustomer>({
        resource: "customers",
        id: showId!,
        metaData: {
            populate: "addresses"
        }
    })

    const useTableQuote = useTable<IQuote>({
        resource: "quotes",
        permanentFilter: [
            {
                field: "customer.id",
                operator: "eq",
                value: customer?.id,
            },
        ],
        initialPageSize: 5
    })

    const useTableInvoice = useTable<IInvoice>({
        resource: "invoices",
        permanentFilter: [
            {
                field: "customer.id",
                operator: "eq",
                value: customer?.id,
            },
        ],
        metaData: {
            publicationState: 'preview'
        },
        initialPageSize: 5
    })

    const useTableAddress = useEditableTable<IAddress>({
        resource: "addresses",
        permanentFilter: [
            {
                field: "customer.id",
                operator: "eq",
                value: customer?.id,
            },
        ],
        hasPagination: false,
        metaData: {
            populate: ["quotes", "invoices"],
            publicationState: 'preview'
        }

    })

    const createAddressModalForm = useModalForm<IAddress>({
        resource: "addresses",
        action: "create",
        redirect: false,
        onMutationSuccess: () => {
            refetch()
        }
    });

    return (
        <Row gutter={[16, 16]}>
            
            {/* Customer info */}
            <Col xl={6} lg={24} xs={24}>
                <Card
                    bordered={false}
                    // style={{
                    //     height: "100%",
                    // }}
                    style={{
                        paddingBottom: 50
                    }}
                >
                    <Space
                        direction="vertical"
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <Typography.Title 
                            level={3} 
                            style={{
                                textAlign: "center",
                                width: "100%",
                            }}>
                            {customer?.company ?  
                                <>{customer?.company}</>
                                :
                                <>{customer?.surname} {customer?.name}</>
                            }
                        </Typography.Title>

                        <Space
                            direction="vertical"
                            style={{
                                width: "100%",
                                textAlign: xl ? "unset" : "center"
                            }}
                        >
                            {customer?.company &&   
                                <>
                                <Typography.Text>
                                    <ShopOutlined /> {customer?.company}
                                </Typography.Text>
                                <Typography.Text>
                                    TVA {customer?.tva}
                                </Typography.Text>
                                <Typography.Text></Typography.Text>
                                
                                <Typography.Text strong>
                                    {customer?.surname} {customer?.name}
                                </Typography.Text>
                                </>
                            }

                            <Typography.Text>
                                <MailOutlined /> <EmailField value={customer?.email} />
                            </Typography.Text>
                            <Typography.Text>
                                <PhoneOutlined /> {customer?.phone}
                            </Typography.Text>
                            <Typography.Text italic style={{fontSize: "11px"}}>
                                <CalendarOutlined /> <DateField format="D MMMM YYYY" value={customer?.createdAt} />
                            </Typography.Text>
                        </Space>

                    </Space>

                </Card>
            </Col>

            <Col xl={18} xs={24}>
                
                {/* Quotes */}
                <List
                    title={t("customers.show.quoteRequests")}
                >
                    <Table {...useTableQuote.tableProps} rowKey="id"
                        onRow={(record, rowIndex) => {
                            return {
                              onClick: event => show('quotes', record.id)
                            }
                          }}
                    >
                        <Table.Column dataIndex="status" title={t("quotes.list.status")} 
                            render={(value) => <StatusTag status={value} />}/>
                        <Table.Column
                            dataIndex="fullAddress" title={t("quotes.list.address")} 
                            render={(value) => value.split(',')[0] + ',' + value.split(',')[1]} />
                        <Table.Column dataIndex="amountOfPlugs" title={t("quotes.list.amountOfPlugs")}  />
                        <Table.Column
                            dataIndex="createdAt"
                            title={t("quotes.list.createdAt")}
                            render={(value) => <DateField format="D MMM YY" value={value} />}
                        />
                    </Table>
                </List>

                {/* Invoices */}
                <List
                    title={t("customers.show.invoices")}
                    breadcrumb={<></>}
                >
                    <Table {...useTableInvoice.tableProps} rowKey="id"
                        onRow={(record, rowIndex) => {
                            return {
                              onClick: event => show('invoices', record.id)
                            }
                          }}
                    >
                        <Table.Column dataIndex="invoiceID" title={t('invoices.list.number')}/>
                        <Table.Column dataIndex="type" title={t('invoices.list.type')} 
                            render={(value) => (formatType(value, t("invoices." + value)))}
                        />
                        <Table.Column dataIndex="publishedAt" title={t('invoices.list.status')} 
                            render={(value) => (<>{value === null && <Tag color="red">{t("invoices.draft")}</Tag>}</>)}
                        />
                        <Table.Column dataIndex="title" title={t('invoices.descriptionItem')} />
                        <Table.Column
                            dataIndex="submissionDate"
                            title={t('invoices.submissionDate')} 
                            render={(value, record: IInvoice) => ((record.publishedAt !== null) && <DateField format="D MMM YY" locales="fr-be" value={value} />)}
                        />
                    </Table>
                </List>

                {/* Addresses */}
                <List
                    title={t("customers.show.addresses")}
                    breadcrumb={null}
                    createButtonProps={{
                        onClick: () => createAddressModalForm.show()
                    }}
                >
                    {/* <Table pagination={false} showHeader={false} dataSource={customer?.addresses}>
                        <Table.Column
                            dataIndex="fullAddress"
                            title={t("customers.show.fullAddress")}
                        />
                    </Table> */}
                    <Form {...useTableAddress.formProps} name="editForm" >
                        <Table pagination={false} showHeader={false} {...useTableAddress.tableProps} >
                            <Table.Column<IAddress>
                                key="fullAddress"
                                dataIndex="fullAddress"
                                title={t("customers.show.fullAddress")}
                                width="100%"
                                render={(value, record) => {
                                    if (useTableAddress.isEditing(record.id)) {
                                        return (
                                            <AddressInputForm isEditForm={true} defaultValue={value} />
                                        );
                                    }
                                    return <TextField value={value} />;
                                }}
                            />
                             <Table.Column<IAddress>
                                align="right"
                                title="Actions"
                                dataIndex="actions"
                                key="actions"
                                render={(_text, record) => {

                                    const isDeletable = (record.quotes.length || record.invoices.length ) ? false : true;
                                    
                                    if (useTableAddress.isEditing(record.id)) {
                                        return (
                                            <Space>
                                                <SaveButton
                                                    {...useTableAddress.saveButtonProps}
                                                    size="small"
                                                />
                                                <Button
                                                    {...useTableAddress.cancelButtonProps}
                                                    size="small"
                                                >
                                                    Cancel
                                                </Button>
                                            </Space>
                                        );
                                    }
                                    return (
                                        <Space>
                                            <EditButton
                                                {...useTableAddress.editButtonProps(record.id)}
                                                size="small"
                                            />
                                            {isDeletable ?
                                                <DeleteButton 
                                                    resourceNameOrRouteName="addresses"
                                                    recordItemId={record.id}
                                                    size="small"
                                                />
                                            : 
                                                <Tooltip title={t('customers.show.addressAssociatedToQuote')} color="orange">
                                                    <IoWarningOutline style={{color: "darkorange", marginTop: "4px" ,fontSize: "22px"}} />
                                                </Tooltip>
                                            }
                                        </Space>
                                    );
                                }}
                            />
                        </Table>
                    </Form>
                </List>

                {/* Add address form */}
                <Modal {...createAddressModalForm.modalProps} title={t('customers.show.addAddress')} >
                    <Form {...createAddressModalForm.formProps} layout="vertical">
                        <Form.Item name="customer" initialValue={showId} hidden>
                            <Input/>
                        </Form.Item>
                        <AddressInputForm />
                    </Form>
                </Modal>

            </Col>
        </Row>
    )
}

type AddressInputFormType = {
    isEditForm?: boolean,
    defaultValue?: string | false
}

const AddressInputForm = ({isEditForm = false, defaultValue=false}: AddressInputFormType) => {
    
    const t = useTranslate();
    const form = Form.useFormInstance();

    const [placeValue, setPlaceValue] = useState<IState | null>(null);
    const updatePlaceValue = (data: IState) => {
        
        if(data?.value?.place_id) {
            geocodeByPlaceId(data?.value.place_id).then(
                results => {
                    form.setFieldsValue({
                        fullAddress: results[0].formatted_address
                        
                    })
                    setPlaceValue({
                        label: results[0].formatted_address,
                        value: data?.value
                    })
                    }
                )
        }

    }

    const style = isEditForm ? { margin: 0 } : undefined;
    const initDefaultValue = defaultValue ? {label: defaultValue, value: defaultValue} : false;
    
    return (
        <>
            <Form.Item name="fullAddress" style={style}>
                <GooglePlacesAutocomplete apiKey={GOOGLE_PLACE_API}
                    selectProps={{
                        placeValue,
                        onChange: updatePlaceValue,
                        defaultValue: initDefaultValue,
                        placeholder: t('customers.show.typeAddress'),
                        autoFocus: true,
                        isClearable: true,
                        name: "fullAddress",
                        inputId: "fullAddress",
                        menuPortalTarget: isEditForm ? document.body : null
                    }} 
                    autocompletionRequest={{
                        componentRestrictions: {
                        country: ['be'],
                        },
                        types: ['address']
                    }}
                />
            </Form.Item>
        </>
    )
}