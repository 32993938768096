import { EmailField, List, ShowButton, useTable } from "@refinedev/antd"
import { Button, Col, Form, FormProps, Input, Row, Table } from "antd";
import { CrudFilters, HttpError, useNavigation, useTranslate } from "@refinedev/core";
import { ICustomer } from "interfaces";
import { SearchOutlined } from "@ant-design/icons";

interface ICustomerFilterVariables {
    q: string
}

export const CustomerList = () => {

    const t = useTranslate();
    const { show } = useNavigation();

    const { tableProps, searchFormProps } = useTable<ICustomer, HttpError, ICustomerFilterVariables>({
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { q } = params;

            filters.push({
                operator: "or",
                value: [
                    {
                        field: "name",
                        operator: "contains",
                        value: q
                    },
                    {
                        field: "surname",
                        operator: "contains",
                        value: q
                    },
                    {
                        field: "phone",
                        operator: "contains",
                        value: q
                    }
                ]
            })

            return filters

        }
    });

    return (
        <List title={t("customers.list.title")}>
            
            <Row gutter={[16, 16]}>
                {/* Filters */}
                <Col span={24}>
                    <Filters formProps={searchFormProps} />
                </Col>

                {/* List */}
                <Col span={24}>
                    <Table {...tableProps} rowKey="id"
                        onRow={(record, rowIndex) => {
                            return {
                            onClick: event => show('customers', record.id)
                            }
                        }}
                    >
                        <Table.Column dataIndex="name" title={t("customers.info.name")} />
                        <Table.Column dataIndex="surname" title={t("customers.info.surname")} />
                        <Table.Column
                            dataIndex="email"
                            title={t("customers.info.email")}
                            render={(value) => <EmailField value={value} />}
                        />
                        <Table.Column dataIndex="phone" title={t("customers.info.phone")} />
                        <Table.Column<ICustomer>
                            title={t("customers.list.actions")}
                            dataIndex="actions"
                            render={(_text, record): React.ReactNode => {
                                return (
                                <ShowButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText 
                                />
                                )
                                }
                            }
                        />
                    </Table>
                </Col>
            </Row>
        </List>

    )
}

const Filters: React.FC<{formProps: FormProps}> = ({formProps}) => {

    const t = useTranslate();

    return (
        <Form layout="inline" {...formProps}>
            <Form.Item 
                // label={t("customers.list.searchLabel")} 
                name="q">
                <Input
                    placeholder={t("customers.list.searchPlaceholder")}
                    prefix={<SearchOutlined />}
                />
            </Form.Item>
            <Form.Item>
                <Button
                    style={{ width: "100%" }}
                    htmlType="submit"
                    type="primary"
                >
                    {t("customers.list.filterSubmit")}
                </Button>
            </Form.Item>
        </Form>
    )

}