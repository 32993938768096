import { List as AntdList, Button, Tag } from "antd";
import { useOne, useTranslate } from "@refinedev/core";
import { IAttachedFile, IQuote } from "interfaces"
import { useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { GoUpload } from 'react-icons/go'
import { AiOutlineEdit } from 'react-icons/ai'
import { IoMdCloudDownload } from 'react-icons/io'
import { UploadFileWithProgress } from "./uploadFileWithProgress"
import { DeleteFileButton } from "./deleteFile"
import { QuotePopulate } from "pages"
import { UpdateFileForm } from "./updateFile"
import dayjs from 'dayjs'

let id=0;

type AttachedFilesProps = {
    quoteId: number;
    quote: IQuote;
}

export interface IUploadingFile {
    file: File;
    id: number;
}

function newId() {
    return ++id
}

export const AttachedFiles = ({quoteId, quote}: AttachedFilesProps) => {

    const items = quote.attachedFiles

    const translate = useTranslate()
    const { refetch } = useOne<IQuote>({
        resource: "quotes",
        id: quoteId,
        meta: {
            populate: QuotePopulate
        }
    })

    // Upload
    const [uploadingFiles, setUploadingFiles] = useState<IUploadingFile[]>([]);

    useEffect( () => {
        refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadingFiles])

    const onDrop = useCallback((files: File[]) => {
        const newFiles = files.map((f) => ({file: f, id: newId()}))
        setUploadingFiles((curr) => [...curr, ...newFiles])
    }, [])

    const {getRootProps, getInputProps} = useDropzone({onDrop})
    
    const onUpload = (id: number) => {
        // Remove file from the UploadingFiles list
        setUploadingFiles((i) => i.filter((f) => f.id !== id))
    }

    // Edit
    const [ editID, setEditID ] = useState<number>(0)
    const isEditing = (id: number) => editID === id ? true : false; 

    const ListActions = (item: IAttachedFile) => {
        if (!isEditing(item.id)) {
            return [<Button type="link" onClick={() => setEditID(item.id)}>
                        <AiOutlineEdit size={20} />
                    </Button> ,
                    <DeleteFileButton fileID={item.id} quote={quote} />
                ]
        }
    }

    return (
        <>
        {items.length > 0 && 
        <AntdList 
            style={{marginBottom: "20px"}}
            dataSource={items}
            renderItem={
                item => (
                <AntdList.Item actions={ListActions(item)}>
                    {isEditing(item.id) ? 
                        <UpdateFileForm item={item} setEditID={setEditID} refetch={refetch} />
                    : 
                        <AntdList.Item.Meta
                            title={<a href={item.file.url} target="_blank" rel="noreferrer" style={{width: "100%"}} ><Tag>{item.file.name.split('.').pop()}</Tag>{item.name} <IoMdCloudDownload size={15} style={{marginLeft: "5px"}} /></a>}
                            description={<>{item.description ? item.description : translate("quotes.show.noFileDescription")}<br/><i style={{color: "silver", fontSize: "12px"}}>{dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}</i></>}
                        />
                    }                 
                </AntdList.Item>
            )}
        />}

        <div {...getRootProps({className: 'ant-upload ant-upload-drag'})} style={{padding: "20px"}}>
            <input {...getInputProps()} />
            <p className="ant-upload-drag-icon">
                <GoUpload size={40} />
            </p>
            <p className="ant-upload-text">{translate("quotes.show.dragAndDropInfoMessage")}</p>
        </div>
        {uploadingFiles.length > 0 && 
        <AntdList 
            dataSource={uploadingFiles}
            renderItem={
                item => (
                <AntdList.Item>
                    <UploadFileWithProgress fileInfo={item} quoteId={quoteId} onUpload={onUpload}  />
                </AntdList.Item>
            )}
        />
        }        
        </>


    )
}