import { Authenticated, Refine } from "@refinedev/core";
import { notificationProvider, AuthPage, ThemedLayoutV2 } from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import routerProvider, { NavigateToResource } from "@refinedev/react-router-v6";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { DataProvider } from "@refinedev/strapi-v4";

import { authProvider, axiosInstance } from "./authProvider";
import { API_URL } from "./constants";
import { CarList, QuoteList, QuoteShow } from "pages";
import { useTranslation } from "react-i18next";

import { TbFileEuro } from 'react-icons/tb';
import { MdOutlineElectricCar } from 'react-icons/md';
import { RiGroupLine } from 'react-icons/ri';
import { IoFileTray } from 'react-icons/io5';

import { CustomerList, CustomerShow } from "pages/customers";
import { InvoiceEdit, InvoiceList, InvoiceShow } from "pages/invoices";
import { Tools } from "pages/tools";
import { ToolOutlined } from "@ant-design/icons";

// import { ThemedHeaderV2 } from "components/themedLayout/header";
import { Sider } from "components/themedLayout/sider";
import { Title } from "components/themedLayout/title";

function App() {

  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  // controlItemBgActive
  return (
      <BrowserRouter>
        <Refine
          notificationProvider={notificationProvider}
          routerProvider={routerProvider}
          authProvider={authProvider}
          dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
          i18nProvider={i18nProvider}
          resources={[{ name: "quotes", 
                        list: QuoteList, 
                        show: QuoteShow,
                        icon: <IoFileTray />,
                        meta: {label: t('quotes.name') as string}
                      },
                      { name: "customers",
                        list: CustomerList,
                        show: CustomerShow,
                        icon: <RiGroupLine />,
                        meta: {label: t('customers.name') as string}
                      },
                      { name: "invoices",
                        list: InvoiceList,
                        show: InvoiceShow,
                        edit: InvoiceEdit,
                        icon: <TbFileEuro />,
                        meta: {label: t('invoices.name') as string}
                      },
                      { name: "cars",
                        list: CarList,
                        icon: <MdOutlineElectricCar />,
                        meta: {label: t('cars.name') as string}
                      },
                      { name: "tools",
                        list: Tools,
                        icon: <ToolOutlined />,
                        meta: {label: t('tools.name') as string}
                      }
                      ]}
          options={{
            disableTelemetry: true
          }}
        >
          <Routes>
            
            {/* Login Page */}
            <Route
                element={
                    <Authenticated fallback={<Outlet />}>
                        <NavigateToResource resource="quotes" />
                    </Authenticated>
                }
            >
              <Route
                  path="/login"
                  element={<AuthPage type="login" />}
              />
            </Route>

            {/* App pages */}
            <Route
                element={
                    <Authenticated redirectOnFail="/login">
                      <ThemedLayoutV2 Title={Title} Sider={Sider}>
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                }
            >
              <Route
                index
                element={<NavigateToResource resource="quotes"/>}
              />
              
              {/* Quotes */}
              <Route path="quotes">
                  <Route index element={<QuoteList />} />
                  <Route path="show/:id" element={<QuoteShow />} />
              </Route>
              
              {/* Customers */}
              <Route path="customers">
                  <Route index element={<CustomerList />} />
                  <Route path="show/:id" element={<CustomerShow />} />
              </Route>

              {/* Invoice */}
              <Route path="invoices">
                  <Route index element={<InvoiceList />} />
                  <Route path="show/:id" element={<InvoiceShow />} />
                  <Route path="edit/:id" element={<InvoiceEdit />} />
              </Route>

              {/* Cars */}
              <Route path="cars">
                  <Route index element={<CarList />} />
              </Route>

              {/* Tools */}
              <Route path="tools">
                  <Route index element={<Tools />} />
              </Route>
            
            </Route>
          </Routes>
        </Refine>
      </BrowserRouter>
  );
}

export default App;
