import { List, DateField, useTable, EmailField, ShowButton } from "@refinedev/antd";
import { Table } from "antd";
import { useNavigation, useTranslate } from "@refinedev/core";

import { IQuote } from "../../interfaces";
import { StatusTag } from "components/quotes/StatusTag";

export const QuoteList: React.FC = () => {

  const { tableProps } = useTable<IQuote>({
    meta: {
      populate: ["evcars"]
    },

    pagination: {
      pageSize: 20
    },

    sorters: {
      initial: [
        {
          field: "createdAt",
          order: 'desc'
        }
      ]
    }
  });

  const { show } = useNavigation();
  const translate = useTranslate();

  // Merge name and surname together
  if (tableProps.dataSource!) {
    const newDataSource = tableProps.dataSource.map((r, index) => {
      r.fullName = r.surname + ' ' + r.name
      return r
    })
    tableProps.dataSource = newDataSource
  }


  return (
    <List title={translate("quotes.list.title")}>
      <Table {...tableProps} rowKey="id" 
        onRow={(record, rowIndex) => {
          return {
            onClick: event => show('quotes', record.id)
          }
        }
      }>
        <Table.Column dataIndex="status" title={translate("quotes.list.status")} 
          render={(value) => <StatusTag status={value} />}/>
        <Table.Column dataIndex="fullName" title={translate("quotes.list.surnameName")} />
        <Table.Column
          dataIndex="email"
          title={translate("quotes.list.email")}
          render={(value) => <EmailField value={value} />}
        />
        <Table.Column
          dataIndex="fullAddress" title={translate("quotes.list.address")} 
          render={(value) => value.split(',')[0] + ',' + value.split(',')[1]} 
        />
        <Table.Column dataIndex="amountOfPlugs" title={translate("quotes.list.amountOfPlugs")}  />
        <Table.Column
          dataIndex="createdAt"
          title={translate("quotes.list.createdAt")}
          render={(value) => <DateField format="D MMM YY" value={value} />}
        />
        <Table.Column<IQuote>
          title={translate("quotes.list.actions")}
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <ShowButton
                size="small"
                recordItemId={record.id}
                hideText 
              />
            )
          }

          }
        />
      </Table>
    </List>
  );
};