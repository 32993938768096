import { DateField, DeleteButton, EditButton, List, ShowButton, useTable } from "@refinedev/antd";
import { Button, Modal, Space, Table, Tag } from "antd";
import { HttpError, useCreate, useNavigation, useNotification, useOne, useTranslate } from "@refinedev/core";
import { formatType } from "helpers/invoices";
import { IInvoice } from "interfaces";
import { CopyOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

export const InvoiceList = () => {

    const t = useTranslate();
    const {edit} = useNavigation();

    const { tableProps} = useTable<IInvoice>({
        meta:{
            populate: ["customer", "address"],
            publicationState: 'preview',   
        },

        sorters: {
            permanent: [{
                field: "id",
                order: "desc"
            }]
        }
    })

    const [openModal, setOpenModal] = useState(false);
    const [idToDuplicate, setIdToDuplicate] = useState(0);
    const [isDuplicating, setIsDuplicating] = useState(false);

    const { mutate } = useCreate<IInvoice>();
    const { data, isLoading, isError } = useOne<IInvoice, HttpError>({
        resource: "invoices",
        id: idToDuplicate,
        meta: {
            populate: ['customer', 'address', 'quote']
        },
        queryOptions: {
            enabled: !!idToDuplicate
        }
    });
    const { open: openNotif } = useNotification();

    const showDuplicateModel = (id: number) => {
        setIdToDuplicate(id)
        setOpenModal(true)
    }
    const closeDuplicateModal = () => {
        setOpenModal(false)
    }

    const duplicateInvoice = () => {
        console.log('Duplicate: '+idToDuplicate)
        // setIsDuplicating(true);

        if (isError) {
            setIdToDuplicate(0);
            setIsDuplicating(false);
            setOpenModal(false);
            openNotif?.({
                type: "error",
                message: "Error getting the invoice data",
            });
        }

        if (!isLoading && !!data?.data.id) {
            let values = data.data;

            console.log(values)
            mutate({
                resource: "invoices",
                values: {
                    type: values.type,
                    customer: values.customer.id,
                    address: values.address.id,
                    title: values.title,
                    description: values.description,
                    items: values.items,
                    subtotal: values.subtotal,
                    taxes: values.taxes,
                    total: values.total,
                    submissionDate: dayjs().toISOString(),
                    validityDate: dayjs().add(1, 'month').toISOString(),
                    publishedAt: null,
                    quote: values.quote.id
                }
            },{
                onSuccess: (data) => {
                    console.log(data.data.id)
                    setIsDuplicating(false);
                    edit('invoices', data.data.data.id)
                }
            })
            
        }

        setIdToDuplicate(0);
    }
    const getModelText = () =>{
        const type = tableProps.dataSource?.find(i => i.id === idToDuplicate)?.type

        if (type === 'quotation') return t('invoices.list.duplicateQuotation') 
        else if (type === 'invoice') return t('invoices.list.duplicateInvoice')
        
        return "ERROR: Unknow type"
    }

    return (
        <List title={t('invoices.list.title')}>
            
            <Table {...tableProps} rowKey="id">
                {/* <Table.Column dataIndex="id" title="id"/> */}
                <Table.Column dataIndex="invoiceID" title={t('invoices.list.number')}/>
                <Table.Column dataIndex="type" title={t('invoices.list.type')} 
                    render={(value) => (formatType(value, t("invoices." + value)))}
                />
                <Table.Column dataIndex="publishedAt" title={t('invoices.list.status')} 
                    render={(value) => (<>{value === null && <Tag color="red">{t("invoices.draft")}</Tag>}</>)}
                />
                <Table.Column dataIndex="customer" title={t('invoices.customer')}
                    render={(value) => <>{value.surname} {value.name}</>}     
                />
                <Table.Column dataIndex="title" title={t('invoices.descriptionItem')} />
                <Table.Column
                    dataIndex="submissionDate"
                    title={t('invoices.submissionDate')} 
                    render={(value, record: IInvoice) => ((record.publishedAt !== null) && <DateField format="D MMMM YY" locales="fr-be" value={value} />)}
                />
                <Table.Column<IInvoice>
                    title={t("invoices.list.actions")}
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                        <Space>
                            <ShowButton
                                size="small"
                                recordItemId={record.id}
                                hideText 
                            />
                            {record.publishedAt === null &&
                            <>
                            <EditButton 
                                size="small"
                                recordItemId={record.id}
                                hideText
                            />
                            <DeleteButton 
                                size="small"
                                recordItemId={record.id}
                                hideText
                            />
                            </>
                            }
                            <Button
                                icon={<CopyOutlined />}
                                size="small"
                                onClick={() => showDuplicateModel(record.id)}
                            />
                        </Space>
                        )
                        }
                    }
                />
            </Table>
            
            <Modal
                title={<><QuestionCircleOutlined style={{color: "orange"}}/> {getModelText()}</>}
                open={openModal}
                onCancel={closeDuplicateModal}
                onOk={duplicateInvoice}
                okText={t('invoices.list.modalOkDuplicate')}
                cancelText={t('buttons.cancel')}
                okButtonProps={{style: {backgroundColor: "orange"} }}
                confirmLoading={isDuplicating}
            >
            </Modal>

        </List>
    )
}