import { TagField } from "@refinedev/antd"
import { statusTagColor } from "helpers/quotes"
import { t } from "i18next"
import { TQuoteStatusValues } from "interfaces";
import { CSSProperties } from "react";

export interface StatusTagProps {
    status: TQuoteStatusValues;
    style?: CSSProperties; 
}

export const StatusTag = ({status, style}: StatusTagProps) => {
    return <TagField value={t("quotes.show.status."+status)} color={statusTagColor(status)} style={style} />
}