import React from "react";
import { CrudFilters, HttpError, useMany, useTranslate } from "@refinedev/core";
import { useTable, List, useSelect } from "@refinedev/antd";

import {
    Table,
    Select,
    Input,
    Form,
    FormProps,
    Button,
    Card,
    Tag,
    Col,
    Row,
    Descriptions,
} from "antd";

import { ICar, ICarBrand } from "interfaces";

interface ICarFilterVariables {
    model: string,
    brand: string,
  }

export const CarList = () => {
    
    const { tableProps, searchFormProps } = useTable<ICar, HttpError, ICarFilterVariables>({
        meta: {
            populate: ["car_brand"]
        },

        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { model, brand } = params;
            filters.push(
                {
                    field: "model",
                    operator: "containss",
                    value: model,
                },
                {
                    field: "car_brand.id",
                    operator: "in",
                    value: brand,
                },
            );

            return filters;
        },

        syncWithLocation: true,

        pagination: {
            pageSize: 20
        },

        filters: {
            permanent: [
                {
                    field: "model",
                    operator: "ne",
                    value: "Autre"
                }
            ]
        },

        sorters: {
            initial: [
                {
                  field: "car_brand.name",
                  order: 'asc'
                }
            ]
        }
    });


    const brandIds = tableProps.dataSource?.map((p) => p.car_brand.id) || [];
    const { data, isFetching } = useMany<ICarBrand>({
        resource: 'car-brands',
        ids: brandIds,
        queryOptions: {
            enabled: brandIds.length > 0,
          },
    });

    const translate = useTranslate();

    return (
        <List>
            <Filter formProps={searchFormProps} />
            
            <Table {...tableProps}
                    rowKey="id" 
                    expandable={{
                        expandedRowRender: expandedRowRender
                    }}
                >

                <Table.Column dataIndex="type" title={translate("cars.type")}
                    render={(value) => {
                        const color = value === 'Électrique' ? "green" : "orange";
                        return <Tag color={color}>{value}</Tag>
                    }}
                />
                
                <Table.Column dataIndex={['car_brand', 'id']} title={translate("cars.brand")}
                render={(value) => {
                    if (isFetching) return translate('loading');

                    return data?.data.find((p) => p.id === value)?.name;
                    
                }}
                />

                <Table.Column dataIndex="model" title={translate("cars.model")} />

                <Table.Column dataIndex="powerAC" title={translate("cars.powerAC")}
                    render={(value) => value + ' kWh'} />

                <Table.Column dataIndex="plugAC" title={translate("cars.plugAC")} />

            </Table>
        </List>
    );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

    const selectCarBrand = useSelect<ICarBrand>({
        resource: 'car-brands',
        optionLabel: 'name',
        optionValue: 'id',
        pagination: {
            pageSize: 1000
        }
        // metaData: {
        //     populate: ["cars"]
        // },
        // filters: [
        //     {
        //         field: "cars.id",
        //         operator: "nnull",
        //         value: true
        //     }
        // ]
      });


    const translate = useTranslate();

    return (
        <Form layout="inline" {...formProps} style={{marginBottom: "20px"}}>
            
            <Form.Item label="" name="brand">
                <Select
                    placeholder={translate("cars.brand")}
                    showSearch
                    style={{ minWidth: 200 }}
                    {...selectCarBrand.selectProps}
                    onSelect={() => formProps.form?.submit()}
                />
            </Form.Item>

            <Form.Item label="" name="model">
                <Input
                    placeholder={translate("cars.model")}
                    // prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>

            <Form.Item>
                <Button htmlType="submit" type="primary" id="submitbutton">
                    {translate("buttons.filter")}
                </Button>
            </Form.Item>
            
            <Form.Item>
                <Button htmlType="reset" type="default" onClick={() => {
                    formProps.form?.resetFields()
                    formProps.form?.submit()
                }
                }>
                    {translate("buttons.clear")}
                </Button>
            </Form.Item>
            
        </Form>
    );
};

const CarDetails : React.FC<{record: ICar}> = ({record}) => {

    const batteryUnit = record.type === "Électrique" ? "kWh" : "l/100km"

    const translate = useTranslate();

    return (
        <>
        <Row>
            <Col span={24}>
                <Card title={translate("cars.technicalDetails")}>
                    <Row><Col span={12}>
                    <Descriptions bordered={true} column={1} size="small" style={{marginBottom: "20px"}}>
                        <Descriptions.Item label={translate("cars.battery")}>{record.battery} {batteryUnit}</Descriptions.Item>
                        <Descriptions.Item label={translate("cars.range")}>{record.range} km</Descriptions.Item>
                    </Descriptions>
                    </Col></Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <h3>{translate("cars.ACCardTitle")}</h3>
                            <Descriptions size="small" bordered={true} column={1}>
                                <Descriptions.Item label={translate("cars.plugAC")}>{record.plugAC}</Descriptions.Item>
                                <Descriptions.Item label={translate("cars.powerAC")}>{record.powerAC} kWh</Descriptions.Item>
                                {record.powerACOption && <Descriptions.Item label={translate("cars.powerACOption")}>{record.powerACOption} kWh</Descriptions.Item>}
                            </Descriptions>
                        </Col>

                        <Col span={12}>
                        {record.plugDC && 
                                <>
                                <h3>{translate("cars.DCCardTitle")}</h3>
                                <Descriptions size="small" bordered={true} column={1}>
                                    <Descriptions.Item label={translate("cars.plugDC")}>{record.plugDC}</Descriptions.Item>
                                    <Descriptions.Item label={translate("cars.powerDC")}>{record.powerDC} kWh</Descriptions.Item>
                                </Descriptions>
                                </>
                        }
                        </Col>
                    
                    </Row>

                </Card>
            </Col>
        </Row>

        </>
        
    )

}

const expandedRowRender = (record: ICar) => {
    return <CarDetails record={record} />
}