import { IQuote } from "interfaces"

// Color the status tag
export const statusTagColor = (status: IQuote['status']) => {
    switch (status) {
        case 'new':
            return 'green'
        case 'quoteSent':
            return 'orange'
        case 'lost':
            return 'magenta'
        case 'ordered':
            return 'cyan'
        case "placed":
            return 'blue'
        case "invoiceSent":
            return 'purple'
        // case "done":
        //     return ''
        default:
            return 'default'
    }
}